/**
 * external libs
 */
import { createContext, useState, useRef, useCallback, useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
/**
 * components
 */
import Router from './routers/Router'
import Alerts from './common-components/alerts/Alerts'
import Loader from './common-components/loader/Loader'
import Cookie from './common-components/cookie/Cookie'

/**
 * types
 */
import { GlobalContextType, AlertType, AlertContentType, CardType, UserContextType, PetrolType } from './types'
/**
 * styles
 */
import './global.scss'
import { useAllData } from './utils/all-data'
import { Range } from 'react-date-range'

export const dpInit: Range[] = [
    {
        startDate: undefined,
        endDate: undefined,
        key: 'selection',
    },
]

export const GlobalContext = createContext<GlobalContextType>({
    addAlert: null,
    cards: null,
    setCards: null,
    user: null,
    changeUser: null,
    setLoaderCount: null,
    pushCount: null,
    setPushCount: null,
    petrol: null,
    setPetrol: null,
    activeCard: null,
    setActiveCard: null,
    selectedCard: null,
    setSelectedCard: () => {},
    services: [],
    setServices: () => {},
    officeRangeDate: dpInit,
    setOfficeRangeDate: () => {},
    // allDrivers: null,
    // setAllDrivers: null,
})

function App() {
    const [alerts, setAlerts] = useState<AlertType[]>([])
    const [cards, setCards] = useState<any | null>(null)
    const [user, setUser] = useState<UserContextType | null>(null)
    const [loaderCount, setLoaderCount] = useState<number>(0)
    const [pushCount, setPushCount] = useState<number>(0)
    const [petrol, setPetrol] = useState<any | null>(null)
    const alertId = useRef<number>(0)
    const [isAcceptedCookie, setIsAcceptedCookie] = useState(!!localStorage.getItem('acceptedCookie'))
    const [activeCard, setActiveCard] = useState<any | null>(null)
    const [selectedCard, setSelectedCard] = useState<any | null>(null)
    const [services, setServices] = useState<any | null>(null)
    const [officeRangeDate, setOfficeRangeDate] = useState<Range[]>(dpInit)

    // const [allDrivers, setAllDrivers] = useState<any | null>(null)

    // const { getAllData } = useAllData()

    // useEffect(() => {
    //     console.log('App render')
    //     if (!user) return
    //     if (user.Role === 'companies') {
    //         const drivers = async () => {
    //             const driversList = await getAllData<CardType>(`/companies/${user.uID}/drivers`)
    //             setAllDrivers(driversList)
    //         }

    //         drivers()
    //     }
    // }, [user])

    const addAlert = useCallback(
        (alert: AlertContentType) => {
            alertId.current = alertId.current + 1
            setAlerts((pre) => [...pre, { ...alert, id: alertId.current }])
        },
        [alertId]
    )

    return (
        <GlobalContext.Provider
            value={{
                petrol,
                setPetrol,
                user,
                changeUser: setUser,
                addAlert,
                cards,
                setCards,
                setLoaderCount,
                pushCount,
                setPushCount,
                activeCard,
                setActiveCard,
                selectedCard,
                setSelectedCard,
                services,
                setServices,
                officeRangeDate,
                setOfficeRangeDate,
                // allDrivers,
                // setAllDrivers,
            }}>
            <Alerts alerts={alerts} setAlerts={setAlerts} />
            {loaderCount > 0 && <Loader />}

            {!isAcceptedCookie && <Cookie setIsAcceptedCookie={setIsAcceptedCookie} />}

            <HelmetProvider>
                <Router />
            </HelmetProvider>
        </GlobalContext.Provider>
    )
}

export default App
