/**
 * external libs
 */
import React, { createContext, PropsWithChildren, useState, useEffect, useContext, useCallback, useRef } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
/**
 * components
 */
import Menu from './Menu'
import Carts from './Carts'
import avatar from './../../assets/icons/avatar.svg'
import logout from './../../assets/icons/log-out.svg'
import StanchionChooser from './../../pages/map/StanchionChooser'
import StanchionInfo from './../../pages/map/StanchionInfo'
import MapList from './../../pages/map/MapList'
import MapContext from './../../pages/map/MapContext'
import Button from './../../common-components/button/Button'
/**
 * context
 */
import { GlobalContext } from './../../App'
/**
 * hooks
 */
import { usePrivateLayoutData } from './hooks'
/**
 * utils
 */
import useSender from './../../utils/sender'
import { useAllData } from './../../utils/all-data'
/**
 * types
 */
import {
    GlobalContextType,
    MenuContextType,
    RolesType,
    RolesTranslateType,
    CardType,
    PetrolType,
    TariffType,
} from './../../types'
/**
 * styles
 */
import styles from './../layouts.module.scss'
import { Transition } from 'react-transition-group'
import DatepickerRangeCalendar from '../../common-components/datepicker-range/DatepickerRangeCalendar'
import { Range } from 'react-date-range'
import Portal from '../../common-components/portal/Portal'
import ReactDOM, { createPortal } from 'react-dom'

export const MenuContext = createContext<MenuContextType>({
    openChat: false,
    setOpenChat: () => {},
    openDrivers: false,
    setOpenDrivers: () => {},
    openFuelCards: false,
    setOpenFuelCards: () => {},
})

const dpInit: Range[] = [
    {
        startDate: undefined,
        endDate: undefined,
        key: 'selection',
    },
]
const PrivateLayout: React.FC<PropsWithChildren> = () => {
    const navigate = useNavigate()

    const { user, changeUser, cards, setCards, addAlert, setPushCount, setPetrol } =
        useContext<GlobalContextType>(GlobalContext)
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
    const { routeData, isMap } = usePrivateLayoutData()
    const [access, setAccess] = useState(false)
    const { logIn, logOut, http } = useSender()
    const { getAllData } = useAllData()
    const [openChat, setOpenChat] = useState(false)
    const [openDrivers, setOpenDrivers] = useState(false)
    const [openFuelCards, setOpenFuelCards] = useState(false)
    const [open, setOpen] = useState<boolean>(false)
    const [dpRangeLocal, setDPRangeLocal] = useState<Range[]>(dpInit)
    const dpWrapperRef = useRef(null)
    const targetRef = useRef<any>(null) // Ссылка на целевой элемент
    const [calendarPosition, setCalendarPosition] = useState({ left: 0, top: 0, transform: 'translateY(-100%)' })

    useEffect(() => {
        const updatePosition = () => {
            if (targetRef.current) {
                const rect = targetRef.current.getBoundingClientRect()
                console.log(rect)
                // Вычисляем позицию относительно viewport
                const newLeft = rect.x // Добавляем прокрутку страницы
                const newTop = rect.y + window.scrollY - 16

                setCalendarPosition({ left: newLeft, top: newTop, transform: 'translateY(-100%)' })
            }
        }

        // Обновляем позицию при загрузке компонента
        updatePosition()

        // Добавляем слушатель для обновления позиции при изменении размера окна или прокрутке
        window.addEventListener('resize', updatePosition)
        // window.addEventListener('scroll', updatePosition)

        // Удаляем слушатели при размонтировании компонента
        return () => {
            window.removeEventListener('resize', updatePosition)
            // window.removeEventListener('scroll', updatePosition)
        }
    }, [open, setOpen])

    const cancelHandler = useCallback(() => {
        setOpen(false)
        setDPRangeLocal(dpInit)
    }, [setOpen, setDPRangeLocal])

    const saveHandler = useCallback(() => {
        setOpen(false)
        downloadReport()
        console.log(dpRangeLocal)
    }, [setOpen, dpRangeLocal])

    const setDPRangeHandler = useCallback(
        (item: Range[]) => {
            const { startDate, endDate }: any = item[0]
            const daysDifference = getDaysBetweenDates(startDate, endDate)
            if (daysDifference > 3) {
                const start = new Date(startDate)
                const end = new Date(endDate)
                if (end > start) {
                    if (end.getMonth() !== start.getMonth()) end.setMonth(start.getMonth())
                    if (end.getFullYear() !== start.getFullYear()) end.setFullYear(start.getFullYear())
                    end.setDate(start.getDate() + 89)
                } else {
                    if (start.getMonth() !== end.getMonth()) start.setMonth(end.getMonth())
                    if (start.getFullYear() !== end.getFullYear()) start.setFullYear(end.getFullYear())
                    start.setDate(end.getDate() - 89)
                }
                setDPRangeLocal([{ startDate: start, endDate: end, key: 'selection' }])
            } else {
                setDPRangeLocal(item)
            }
        },
        [setDPRangeLocal]
    )

    function getDaysBetweenDates(startDate: any, endDate: any) {
        const start: any = new Date(startDate)
        const end: any = new Date(endDate)
        const timeDifference = end - start
        const daysDifference = timeDifference / (1000 * 3600 * 24)

        return daysDifference + 1
    }

    const downloadReport = async () => {
        if (user?.Role && user?.uID && (user?.Role === RolesType.companies || user?.Role === RolesType.individuals)) {
            try {
                const { data } = await http.get(`/${user.Role}/${user.uID}/transactions/xlsx`, {
                    params: {
                        dateStart: dpRangeLocal?.[0]?.startDate,
                        dateEnd: dpRangeLocal?.[0]?.endDate,
                    },
                    responseType: 'blob',
                })
                const url = window.URL.createObjectURL(
                    new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                )

                // Создаем ссылку и автоматически кликаем по ней для скачивания файла
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'transactions.xlsx') // указываем имя файла
                document.body.appendChild(link)
                link.click()
                link.remove() // удаляем ссылку после скачивания
            } catch (e: any) {
                if (addAlert) {
                    addAlert({
                        text: `Ошибка загрузки данных личного кабинета: ${e?.code} | ${e?.status} | ${e?.config?.url}`,
                        type: 'error',
                    })
                }
            }
        }
    }

    const firstVisitHandler = useCallback(() => {
        if (user?.uID && user?.Role) {
            setAccess(true)
        } else {
            const accessToken = sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken')
            const refreshToken = sessionStorage.getItem('refreshToken') || localStorage.getItem('refreshToken')
            // console.log(accessToken)
            // console.log(sessionStorage.getItem('accessToken'))
            // console.log(localStorage.getItem('accessToken'))
            // console.log(refreshToken)
            // console.log(sessionStorage.getItem('refreshToken'))
            // console.log(localStorage.getItem('refreshToken'))
            const uIDLocal = localStorage.getItem('uid')
            const roleLocal = localStorage.getItem('urole')
            const unameLocal = localStorage.getItem('uname')
            const companyidLocal = localStorage.getItem('companyid')

            if (accessToken && refreshToken && uIDLocal && roleLocal && changeUser) {
                logIn(accessToken, refreshToken, uIDLocal, roleLocal, unameLocal || '', companyidLocal || '')
                let user: any = {
                    Role: roleLocal as keyof typeof RolesType,
                    uID: uIDLocal,
                    uName: unameLocal,
                }
                if (companyidLocal) user.companyid = companyidLocal
                changeUser(user)

                setAccess(true)
            } else {
                logOut()
            }
        }
    }, [user, setAccess])

    useEffect(() => {
        firstVisitHandler()
    }, [firstVisitHandler])

    useEffect(() => {
        const getCards = async () => {
            console.log(user?.Role)
            if (user?.Role === RolesType.operator || user?.Role === RolesType.managers) {
                navigate(`/manage`)
            }
            if (user?.Role && user?.uID && user?.Role !== RolesType.operator && user?.Role !== RolesType.managers) {
                try {
                    const responses = await Promise.all([
                        user.Role === RolesType.companies
                            ? http.get(`/${user.Role}/${user.uID}/cards`)
                            : getAllData(`/${user.Role}/${user.uID}/cards`),
                        http.get('/notifications/sentCount'),
                        http.get('/tariffs'),
                    ])

                    console.log(responses[2])

                    if (setPetrol) {
                        setPetrol(responses[2]?.data?.data || [])
                    }
                    if (setCards) {
                        setCards(responses[0] || [])
                    }

                    if (setPushCount) {
                        setPushCount((responses[1].data as { count: number }).count || 0)
                    }
                } catch (e: any) {
                    if (addAlert) {
                        addAlert({
                            text: `Ошибка загрузки данных личного кабинета: ${e?.code} | ${e?.status} | ${e?.config?.url}`,
                            type: 'error',
                        })
                    }
                }
            }
        }

        getCards()
    }, [setCards, user, setPushCount, setPetrol])

    if (!access || !cards) {
        return null
    }

    const calendar = ReactDOM.createPortal(
        <Transition nodeRef={dpWrapperRef} in={open} timeout={250} unmountOnExit={false}>
            {(state: string) => (
                <div
                    ref={dpWrapperRef}
                    style={calendarPosition}
                    className={`${styles.calendar__form} ${state === 'entering' || state === 'entered' || state === 'exiting' ? styles.calendar__form_vis : ''} ${state === 'entered' ? styles.calendar__form_show : ''}`}>
                    <DatepickerRangeCalendar
                        handleSelect={(item) => setDPRangeHandler([item.selection])}
                        dpRange={dpRangeLocal}
                    />

                    <div className={styles.calendar__footer}>
                        <Button label="Отмена" handler={cancelHandler} buttonStyle="outline" />

                        <Button label="Принять" handler={saveHandler} />
                    </div>
                </div>
            )}
        </Transition>,
        document.body
    )

    return (
        <>
            <Helmet>
                <title>{routeData?.title ?? ''}</title>
                <meta name="description" content={routeData?.description ?? ''} />
            </Helmet>

            <MenuContext.Provider
                value={{ openChat, setOpenChat, openDrivers, setOpenDrivers, openFuelCards, setOpenFuelCards }}>
                <div className="container">
                    <div className={`${styles.officeLayout} ${isMap ? styles.officeLayout_map : ''}`}>
                        <div className={styles.officeLayout__drawerWrapper}>
                            {!!isMap ? (
                                <MapContext>
                                    <Button
                                        handler={() => navigate('/office')}
                                        label="Вернуться на главную"
                                        type="button"
                                        subClasses={styles.officeLayout__toMainButton}
                                    />

                                    <div
                                        className={`${styles.officeLayout__drawer} ${styles.officeLayout__drawer_input}`}>
                                        <StanchionChooser />
                                    </div>

                                    <StanchionInfo />

                                    <MapList />

                                    <Outlet />
                                </MapContext>
                            ) : (
                                <div
                                    className={`${styles.officeLayout__drawer} ${drawerOpen ? styles.officeLayout__drawer_open : ''}`}>
                                    <div className={styles.officeLayout__drawerScroll}>
                                        <div className={styles.officeLayout__opener}>
                                            <button
                                                type="button"
                                                className={styles.officeLayout__openButton}
                                                onClick={() => setDrawerOpen((pre) => !pre)}>
                                                <svg
                                                    className={styles.officeLayout__openIco}
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M9.6001 16.8L14.4001 12L9.6001 7.20005"
                                                        strokeWidth="1.8"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </button>
                                        </div>

                                        <Menu />
                                        <Carts />
                                        <div className={styles.report}>
                                            <p className={styles.report__title}>Аналитика</p>
                                            <div className={styles.calendar__wrapper}>
                                                <button
                                                    ref={targetRef}
                                                    className={styles.report__button}
                                                    type="button"
                                                    onClick={() => setOpen(true)}>
                                                    Скачать отчет
                                                </button>
                                                {open && (calendar as JSX.Element)}
                                            </div>
                                        </div>
                                        <div className={styles.user}>
                                            <div className={styles.user__logoWrap}>
                                                <img src={avatar} alt="avatar" />
                                            </div>

                                            <div className={styles.user__info}>
                                                <p className={styles.user__label}>{user?.uName || ''}</p>
                                                {!!user?.Role && (
                                                    <p className={styles.user__name}>
                                                        {RolesTranslateType[user?.Role]}
                                                    </p>
                                                )}
                                            </div>

                                            <button type="button" className={styles.user__button} onClick={logOut}>
                                                <img src={logout} alt="log-out" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {!isMap && (
                            <div className={styles.officeLayout__content}>
                                <Outlet />
                            </div>
                        )}
                    </div>
                </div>
            </MenuContext.Provider>
        </>
    )
}

export default PrivateLayout
