/**
 * external libs
 */
import { useEffect, useMemo, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
/**
 * context
 */
import { GlobalContext } from './../../App'
/**
 * utils
 */
import { privateRoutes } from '../../routers/paths'
import useSender from './../../utils/sender'
/**
 * types
 */
import { GlobalContextType, RouteType, GasStationType, GasStationServerType, RolesType } from '../../types'

export const DEFAULT_COORDINATES = [55.71366, 37.47544]

export const usePrivateLayoutData = () => {
    const { addAlert, user } = useContext<GlobalContextType>(GlobalContext)
    const { http } = useSender()
    const location = useLocation()
    const [gasStations, setGasStation] = useState<GasStationType[] | null>(null)
    const [fuelFilter, setFuelFilter] = useState<string[]>([])
    const [servicesFilter, setServicesFilter] = useState<string[]>([])
    const [coordinatesFilter, setCoordinatesFilter] = useState<number[]>(DEFAULT_COORDINATES)
    const [searchFilter, setSearchFilter] = useState<string>('')
    const [ymapsObject, setYmapsObject] = useState<object | null>(null)

    const routeData = useMemo<RouteType | undefined>(
        () => privateRoutes.find((r: RouteType) => r.path === location.pathname),
        [location]
    )

    const isMap = useMemo(() => location.pathname === '/map', [location.pathname])

    useEffect(() => {
        const getStationsData = async () => {
            try {
                const extraPartOfUrl = JSON.stringify({
                    coordinates: coordinatesFilter.join(' '),
                    services: [...fuelFilter, ...servicesFilter],
                    isTrassa: user?.Role === RolesType.individuals ? true : false,
                    search: searchFilter,
                })

                const { data } = await http.get(`/stations/nearest?filters=${extraPartOfUrl}&limit=10000`)
                console.log('Request on gasStations')
                setGasStation(
                    (data as GasStationServerType[]).map((value: GasStationServerType) => ({
                        ...value,
                        coordinates: value.coordinates.split(' ').map((coord) => Number(coord)),
                    }))
                )
            } catch (e: any) {
                if (addAlert) {
                    addAlert({ text: `Ошибка запроса: ${e?.code} | ${e?.status} | ${e?.config?.url}`, type: 'error' })
                }
            }
        }

        getStationsData()
    }, [fuelFilter, servicesFilter, searchFilter])

    return {
        routeData,
        isMap,
        gasStations,
        coordinatesFilter,
        fuelFilter,
        setFuelFilter,
        servicesFilter,
        setServicesFilter,
        setCoordinatesFilter,
        searchFilter,
        setSearchFilter,
        ymapsObject,
        setYmapsObject,
    }
}
