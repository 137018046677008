/**
 * external libs
 */
import React from 'react'
/**
 * components
 */
import ChevronLeft from './../../assets/icons/chevron-left.svg'
/**
 * types
 */
import { DriverWithCardsType } from './../../types'
/**
 * styles
 */
import styles from './driver.module.scss'

type Props = {
    driver: DriverWithCardsType
    handler: React.Dispatch<any>
    wallet?: any
}

const Driver: React.FC<Props> = ({ driver, handler, wallet = null }) => {
    // if (!driver.cards.length) {
    //     return null
    // }

    return (
        <div className={styles.driver} onClick={handler}>
            <div className={styles.driver__data}>
                {!!driver.fio && (
                    <p className={styles.driver__name}>
                        {wallet ? `Номер карты: ${wallet?.limit?.cardNumber}` : driver.fio}
                    </p>
                )}

                {driver.cards.length > 0 && wallet === null && (
                    <div className={styles.driver__card}>
                        <p className={styles.driver__label}>Карта:</p>

                        {driver?.cards?.map((card) => (
                            <div className={styles.driver__numberBG} key={`${driver.id}_${card.id}`}>
                                <p className={styles.driver__number}>{card.cardNumber || ''}</p>
                            </div>
                        ))}
                    </div>
                )}

                {wallet?.limit?.dailyLimit > 0 && (
                    <div className={styles.driver__card}>
                        <p className={styles.driver__label}>Дневной лимит:</p>

                        <div className={styles.driver__numberBG}>
                            <p className={styles.driver__number}>{wallet?.limit?.dailyLimit || ''}</p>
                        </div>
                    </div>
                )}

                {wallet?.limit?.weeklyLimit > 0 && (
                    <div className={styles.driver__card}>
                        <p className={styles.driver__label}>Недельный лимит:</p>

                        <div className={styles.driver__numberBG}>
                            <p className={styles.driver__number}>{wallet?.limit?.weeklyLimit || ''}</p>
                        </div>
                    </div>
                )}

                {wallet?.limit?.monthlyLimit > 0 && (
                    <div className={styles.driver__card}>
                        <p className={styles.driver__label}>Месячный лимит:</p>

                        <div className={styles.driver__numberBG}>
                            <p className={styles.driver__number}>{wallet?.limit?.monthlyLimit || ''}</p>
                        </div>
                    </div>
                )}
            </div>

            <img src={ChevronLeft} alt={driver.fio} className={styles.driver__arrow} />
        </div>
    )
}

export default Driver
